import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Circle from "../../calculations/circle";
import TitleTemplate from "../../component/sections/titleTemplate";
import { MathComponent } from "mathjax-react";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
const TRACKING_ID = "G-H6HVLL90WP";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const Commonchord = () => {
  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [coeficient, setcoeficient] = useState({
    g1: "",
    f1: "",
    c1: "",
    g2: "",
    f2: "",
    c2: "",
  });
  const [result, setResult] = useState("");

  const circle = new Circle(
    coeficient.g1,
    coeficient.f1,
    coeficient.c1,
    coeficient.g2,
    coeficient.f2,
    coeficient.c2
  );
  console.log(coeficient);

  const onG1Change = (e) => {
    setcoeficient({
      ...coeficient,
      g1: e.target.value,
    });
  };
  const onF1Change = (e) => {
    setcoeficient({
      ...coeficient,
      f1: e.target.value,
    });
  };
  const onC1Change = (e) => {
    setcoeficient({
      ...coeficient,
      c1: e.target.value,
    });
  };
  const onG2Change = (e) => {
    setcoeficient({
      ...coeficient,
      g2: e.target.value,
    });
  };
  const onF2Change = (e) => {
    setcoeficient({
      ...coeficient,
      f2: e.target.value,
    });
  };
  const onC2Change = (e) => {
    setcoeficient({
      ...coeficient,
      c2: e.target.value,
    });
  };

  const resultDumm = () => {
    const result = circle.commonchord(
      coeficient.g1,
      coeficient.f1,
      coeficient.c1,
      coeficient.g2,
      coeficient.f2,
      coeficient.c2
    );
    let x = result[0];
    let y = result[1];
    let z = result[2];
    let a = result[3];

    let c = (
      <MathComponent
        tex={`\\displaylines{(${x})x + (${y})y + (${z}) = 0   \\\\ Length \\ of \\ Common \\ Chord = ${a}}`}
      />
    );
    setResult(c);
  };

  const onResult = () => {
    coeficient.g1 === "" ||
    coeficient.f1 === "" ||
    coeficient.c1 === "" ||
    coeficient.g2 === "" ||
    coeficient.f2 === "" ||
    coeficient.c2 === ""
      ? alert("Enter all inputs")
      : check_circle1();
  };

  const check_circle1 = () => {
    ((coeficient.g1 / 2) * coeficient.g1) / 2 +
      ((coeficient.f1 / 2) * coeficient.f1) / 2 -
      coeficient.c1 <=
    0
      ? alert("The Equation of First circle is invalid! Enter Valid Input")
      : check_circle2();
  };

  const check_circle2 = () => {
    ((coeficient.g2 / 2) * coeficient.g2) / 2 +
      ((coeficient.f2 / 2) * coeficient.f2) / 2 -
      coeficient.c2 <=
    0
      ? alert("The Equation of Second circle is invalid! Enter Valid Input")
      : check_pos();
  };

  const check_pos = () => {
    function cenAndrad(g, f, c) {
      let c1 = -g / 2;
      let c2 = -f / 2;
      let a = c1 * c1;
      let b = c2 * c2;
      let r = Math.sqrt(Number(a) + Number(b) - Number(c));

      return [c1.toFixed(3), c2.toFixed(3), r.toFixed(3)];
    }
    function distance(ax, ay, bx, by) {
      let first = Number(ax) - Number(bx);
      let second = Number(ay) - Number(by);
      let result = Math.sqrt(first * first + second * second);

      return Number(result.toFixed(2));
    }

    let c1 = cenAndrad(coeficient.g1, coeficient.f1, coeficient.c1);
    let c2 = cenAndrad(coeficient.g2, coeficient.f2, coeficient.c2);

    let d = distance(c1[0], c1[1], c2[0], c2[1]);

    d + c2[2] <= c1[2]
      ? alert(
          "One Circle lies completely inside another circle! Common Chord does not exist! Enter Valid Input"
        )
      : resultDumm();
  };

  const f =
    "\\displaylines{Common \\ Chord = S_1 - S_2 =0 \\\\ \\\\ Length \\ of \\ Common \\ Chord  = 2\\sqrt{r^2-d^2}}";
  const f1 = <MathComponent tex={f} />;

  return (
    <div>
      <Helmet>
        <title>Equation and length of common chord of two circles.</title>
        <meta
          name="description"
          content="Free calculator to find Equation and length of common chord of two circles."
        />
      </Helmet>
      <TitleTemplate
        title={"Equation and length of common chord of two circles"}
        type={"twocircles"}
        formula={f1}
        valueA={coeficient.g1}
        valueB={coeficient.f1}
        valueC={coeficient.c1}
        valueD={coeficient.g2}
        valueE={coeficient.f2}
        valueF={coeficient.c2}
        onAChange={onG1Change}
        onBChange={onF1Change}
        onCChange={onC1Change}
        onDChange={onG2Change}
        onEChange={onF2Change}
        onFChange={onC2Change}
        onResult={onResult}
        result={result}
      />
    </div>
  );
};

export default Commonchord;
